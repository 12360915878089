import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import { Mutations, Actions } from "@/store/enums/StoreEnums"
import JwtService from "@/core/services/JwtService"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      isAdmin: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/states",
        name: "states",
        component: () => import("@/views/admin/states/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/states/add",
        name: "states-add",
        component: () => import("@/views/admin/states/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/states/edit/:id",
        name: "states-edit",
        component: () => import("@/views/admin/states/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/cities/:id",
        name: "cities",
        component: () => import("@/views/admin/cities/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/cities/add/:id",
        name: "cities-add",
        component: () => import("@/views/admin/cities/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/cities/edit/:state/:id",
        name: "cities-edit",
        component: () => import("@/views/admin/cities/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/admin/users/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/users/add",
        name: "users-add",
        component: () => import("@/views/admin/users/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/users/edit/:id",
        name: "users-edit",
        component: () => import("@/views/admin/users/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/packages",
        name: "packages",
        component: () => import("@/views/admin/packages/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/packages/add",
        name: "packages-add",
        component: () => import("@/views/admin/packages/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/packages/edit/:id",
        name: "packages-edit",
        component: () => import("@/views/admin/packages/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/banners",
        name: "banners",
        component: () => import("@/views/admin/banners/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/banners/add",
        name: "banners-add",
        component: () => import("@/views/admin/banners/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/banners/edit/:id",
        name: "banners-edit",
        component: () => import("@/views/admin/banners/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/factors",
        name: "factors",
        component: () => import("@/views/admin/factors/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/pages/:slug",
        name: "pages",
        component: () => import("@/views/admin/pages/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "ورود",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/payment-canceled",
        name: "payment-canceled",
        component: () =>
          import("@/views/crafted/authentication/PaymentCanceled.vue"),
        meta: {
          pageTitle: "Payment Canceled",
        },
      },
      {
        path: "/payment-done/:refId",
        name: "payment-done",
        component: () =>
          import("@/views/crafted/authentication/PaymentDone.vue"),
        meta: {
          pageTitle: "Payment Done",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  NProgress.set(0.1)

  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG)

  // verify auth token before each page change
  await store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      if (to.meta.isAdmin) {
        if (store.getters.currentUser.role !== 1 && store.getters.currentUser.role !== 2) {
          next({
            path: "/500",
          })
          return
        }
        if (typeof to.meta.justAdmin !== undefined && to.meta.justAdmin) {
          if (store.getters.currentUser.role !== 1) {
            next({
              path: "/500",
            })
            return
          }
        }
      }
      next()
    } else {
      next({ name: "sign-in" })
    }
  } else {
    next()
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
})

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500)
})

export default router
